<template>
  <main class="content-page"></main>
</template>

<script>
export default {
  mounted() {
    console.log("DASHBOARD MOUNTED");
  },
};
</script>

<style scoped>
@import url("../../../assets/scopeListings.css");
.content-page {
  background-image: url("../../../assets/temp/dashboard.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>